<template>
    <transition name="popin">
        <popin v-if="summaryIsOpen" @bgClick="onCancel">
            <div class="font-4 mb-5 popin__message text-center">
                <img :src="area.logo?.content_url" class="logo" v-if="showLogo" :alt="area.name"/>
                <p class="typo-1 my-5 welcome-mobile"
                   v-if="meeting.welcome_text_app"
                   v-html="meeting.welcome_text_app"></p>
                <p class="typo-2 mb-5 mt-3" v-if="name" v-html="name"></p>
                <div class="row justify-content-center mb-4">
                    <div class="col-auto d-flex">
                        <icon name="clock" class="me-3"></icon>
                        <span class="font-4">{{ duration }}</span>
                    </div>
                    <div class="col-auto d-flex" v-if="meetingType.icon !== 'other'">
                        <icon :name="meetingType.icon" class="me-3"></icon>
                        <span class="font-4" v-html="meetingType.label"></span>
                    </div>
                </div>
                <div v-if="description" v-html="description" class="rte"></div>
            </div>
            <div class="d-flex justify-content-center">
                <button class="btn btn-primary" @click="onConfirm">Planifier
                </button>
            </div>
        </popin>
    </transition>
</template>

<script>
import popin from '../../composables/popin';
import global from '../../composables/global';
import { toRef } from 'vue';

export default {
    setup() {
        const { summaryIsOpen } = popin;
        const {
            state: { meeting: { area, name, description }, meeting, showLogo },
            formatedDuration: duration,
            meetingType,
        } = global;

        const onConfirm = function () {
            summaryIsOpen.value = false;
        };
        const onCancel = function () {
            summaryIsOpen.value = false;
        };

        return {
            summaryIsOpen,
            meeting,
            onConfirm,
            onCancel,
            showLogo,
            area,
            name,
            description,
            duration,
            meetingType,
        };
    },
};
</script>
